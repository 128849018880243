<template>
  <dash-page-new
      :title="$t('Templates')"
  >
  <h1>Mailer Templates will be here</h1>
  </dash-page-new>
</template>

<script>
export default {
  name: "mailerLetters"
}
</script>

<style scoped>

</style>