import { render, staticRenderFns } from "./MailerTemplates.vue?vue&type=template&id=890e716e&scoped=true&"
import script from "./MailerTemplates.vue?vue&type=script&lang=js&"
export * from "./MailerTemplates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890e716e",
  null
  
)

export default component.exports